:root{
  --clr-primary: #32577E;
  --clr-primary-dark: #2A3478;
  --clr-danger: #FF0000;
  --side-bar-width: 80px;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

header{
  padding-right: 0px !important;
}