.container {
    position: relative;
    margin: 1rem auto;
}

/* .line-vertical::before {
    content: '';
    position: absolute;
    height: calc(100% - 2rem);
    width: 2px;
    background-color: black;
    left: 50%;
    transform: translateX(-50%);
} */

.row {
    margin: 0.15rem auto;
}

.invoice__table{
    display: none;
}

.detailed__table__print{
    display: none !important;
}

@media print{
    .MuiAppBar-root{
        display: none !important;
    }
    tr{
        display: table-row !important;
    }
    .coupon__row__ui, .total__price__row__ui{
        display: none !important;
    }
    .td_print{
        position: static !important;
    }
    .invoice__table{
        display: block;
    }
    .detailed__table__print{
        page-break-before: always;
    }
    .detailed__table__ui{
    display: none !important;   
    }
    .detailed__table__print{
        display: block !important;
    }
}

